//
// Modal
//

// Base
.modal {
	
	// Mobile header
	.modal-header {
		align-items: center;
		justify-content: space-between;
		@include border-top-radius($modal-content-border-radius);

		// Headings
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 0;
		}
	}

	&.show {
		display: flex !important;
	}

	// Mobile dialog
	.modal-dialog {
		outline: none !important;

		display: flex;
		align-items: center;
	}

}

// Utilities
.modal-rounded {
	@if $enable-rounded {
		border-radius: $modal-content-border-radius !important;
	}
}

// Edited
@media (min-width: 576px){
	.modal-dialog {
		max-width: 80vw;
		margin: 1.75rem auto;
	}
}

@media (min-width: 576px){
	.modal-content {
		max-height: 100%;
		overflow: auto;
	}
}
