.loader-container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 99999;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);

	&.aside-loader-container {
		position: relative;
		background-color: transparent;
		width: 10vw;
		height: 10vw;
		justify-self: center;
		
		& .lds-ring {
			width: 10vw;
			height: 10vw;

			& div {
				width: 7vw;
				height: 7vw;
				border: 1vw solid #fed;
				border-color: $secondary transparent transparent transparent;
			}
		}
	}

	&.aside-loader-container-small {
		position: relative;
		background-color: transparent;
		width: 4vw;
		height: 4vw;
		justify-self: center;
	
		& .lds-ring-small {
			width: 2vw;
			height: 2vw;
			display: inline-block;
			position: relative;
	
			& div {
				width: 2vw;
				height: 2vw;
				border: 0.2vw solid #fed;
				border-color: $secondary transparent transparent transparent;
				box-sizing: border-box;
				display: block;
				position: absolute;
				border-radius: 50%;
				animation: lds-ring 1.2s linear infinite;
			}
		}
		& .lds-ring-small div:nth-child(1) {
			animation-delay: -0.275s;
		}
		& .lds-ring-small div:nth-child(2) {
			animation-delay: -0.550s;
		}
	}

}

.small-loader-with-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1vw;
	text-align: center;
}

.loader-hidden {
	display: none;
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 12vw;
	height: 12vw;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 6vw;
	height: 6vw;
	margin: 0.5vw;
	border: 0.5vw solid #fed;
	border-radius: 50%;
	animation: lds-ring 1.2s linear infinite;
	border-color: $secondary transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.6s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
