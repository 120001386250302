.nfe-card {
	width: 45vw;
	display: flex;
	flex-direction: column;
	gap: 0.7vw;
	border-radius: 8px;
	word-wrap: break-word;
	background-color: $bg-secondary;
	padding: 1vw 1.5vw;

	box-shadow: $box-shadow;

	& .nfe-card-header {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		& i {
			font-size: 1.5vw;
			color: $primary;
		}
	}

	& p {
		margin: 0;
	}
}

.cardContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.7vw;
	align-items: center;
	padding-top: 3vw;
	padding-bottom: 3vw;
}
