
.step {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 2vw;

	& p {
		margin: 0;
	}

	& i {
		font-size: 1.4vw;
	}

	&.done {
		& i {
			color: $primary;
		}
	}
	&.selected {
		& i {
			color: $secondary;
		}
	}
	&.todo {
		& i {
			color: $text-secondary;
		}
	}
}

.step + .step::before {
	content: "";
	position: absolute;
	margin-left: 0.65vw;
	margin-bottom: 3.5vw;
	z-index: -1;
	border-left: .22vw dotted $text-secondary;
	height: 2vw;
}
