
.step-stack {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	padding: 2vw;
	padding-top: 3vw;
	gap: 2vw;
	z-index: 2;
	align-items: flex-start;
	justify-items: flex-end;

	& .step-stack-header {
		display: flex;
		width: 100%;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		
		& p {
			font-size: 1vw;
			margin: 0;
		}

		& i {
			color: $secondary;
			font-size: 0.8vw;
		}
	}

	& .step-stack-list {
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: column;
		gap: 2vw;
		align-items: flex-start;
		justify-items: flex-end;
	}

	& .acnfagro-logo {
		justify-self: center;
		align-self: center;
		min-width: 7vw;
	}
}
