
.plans-list-item {
	color: $text-primary;
	display: flex;
	align-items: center;
	padding: 0;
	width: 100%;

	.plan-name {
		width: 50%;
	}

	.plan-price {
		width: 50%;
	}
}