
.cest-container {
	overflow-y: scroll;
}

.default-cest-content {
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 5vw;
	gap: 2vw;
	text-align: center;

	& p {
		margin: 0;
		font-size: 1.1vw;
	}

	& i {
		font-size: 6vw;
		color: $primary;
	}
	
}