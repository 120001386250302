.basic-modal-container {
	background-color: $bg-footer !important;
	display: flex;
	flex-direction: column;
	padding: 2vw;
	border-radius: 2vw;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}