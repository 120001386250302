
.navigator-button {
	color: white;
	border: none;
	background-color: transparent;
	border-bottom: 3px solid transparent;
	padding: 0 1vw;
	box-shadow: none;

	&.selected {
		border-bottom: 3px solid $secondary;
	}
}