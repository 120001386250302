p.title {
	font-size: 1vw;
	font-weight: bold;
	color: $text-title;
}

p.primary {
	font-size: 0.8vw;
	font-weight: 500;
	color: $text-primary;
}

p.secondary {
	font-size: 0.8vw;
	color: $text-secondary;
}

// h1.page-title {
// 	font-size: 2.5rem;
// }
