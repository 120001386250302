.person-button {
	background-color: $btn-secondary;
	box-shadow: $box-shadow;
	width: 85%;
	display: flex;
	flex-direction: row;
	//padding: 1vw 1.5vw;
	padding: 0;
	gap: 2vw;
	align-items: center;
	border: none;

	& .person-select-button {
		background-color: $btn-secondary;
		width: 100%;
		box-shadow: none;
		display: flex;
		flex-direction: row;
		padding: 1vw 1.5vw;
		gap: 2vw;
		align-items: center;
		border: none;
		& p {
			margin-bottom: 0.2vw;
		}
		& .primary {
			font-size: 0.8vw;
		}
		& .left {
			flex-grow: 2;
			text-align: left;
		}
		& i {
			font-size: 1.4vw;
		}
	}
	
	& .person-delete-button {
		display: flex;
		padding-right: 2vw;
		align-items: center;
		justify-content: center;
	}

	&.selected {
		outline: $button-outline;
	}

}

.person-button:hover{
	outline: $button-outline;
	box-shadow: $box-shadow-hover;
}
