.product-card {
	width: 20%;
	font-size: 1.2vw;

	display: flex;
	flex-direction: column;
	padding: 0 0 0.5vw 0;

	gap: 0.5vw;
	border-radius: 2px;
	border: none;
	background-color: $bg-product-card;

	&:hover {
		outline: $button-outline;
	}

	& p.title {
		padding-right: 1vw;
		align-self: flex-end;
	}

	& p.secondary {
		&::first-letter {
			text-transform: capitalize;
		}
		align-self: flex-start;
		padding-left: 1vw;
	}

	& img.product-card-image {
		max-height: 5vw;
		width: 100%;
		object-fit: cover;
	}
}

.product-list-card {
	width: 85%;
	border: none;
	font-size: 1.2vw;
	padding: 1vw;
	display: flex;
	flex-direction: row;
	text-align: left;
	gap: 0.5vw;
	background-color: $bg-secondary;
	justify-content: space-between;

	&:hover {
		outline: $button-outline;
		box-shadow: $box-shadow-hover;
	}
}
