
.input-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4vw;
	gap: 4vw;

	& .product-quantity-input {
		display: flex;
		flex-direction: row;
		gap: 1vw;
	}

	& .product-total-display {
		text-align: center;
		& p {
			margin: 0;
		}
		& p.primary {
			font-size: 1.5vw;
		}
	}
}