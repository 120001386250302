//
// Footer
//

.footer {
	background-color: get($footer-config, bg-color);
}

.footer-disabled {
	display: none !important;
}
