
.config-modal-container {
	display: flex;
	width: 20vw;
	.config-item-list{
		display: flex;
		flex-direction: column;
		width: 100%;
		
		.config-item-button {
			cursor: pointer;
			border-top: 1px solid $text-secondary;
			border-bottom: 1px solid $text-secondary;
			margin-top: -1px;
			width: 100%;
			display: flex;
			padding: 1vw 4vw;
			align-items: center;
			justify-content: center;
			text-align: center;

			&:hover {
				background: #00000027;
			}
		}
	}
}