.default-input {
	font-size: 1.5vw;
	width: 100%;
	background-color: $bg-input;
	color: black;
	box-shadow: $box-shadow;
	border: none;
	border-radius: 0.3vw;
	outline: none;

	padding: 0.5vw;

	&.read-only {
		background-color: $bg-input-read-only;
		color: $text-primary;
	}
}

.dark-switch {
	background-color: #4e4e4e !important
}
.dark-switch:checked {
	background-color: #6cae17 !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
}