.products-container-images {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	gap: 1vw;
	align-items: center;
	justify-content: center;
}

.products-container-list {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: .5vw;
	align-items: center;
}