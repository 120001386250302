button {
	box-shadow: $box-shadow;
}

button:hover {
	transition: box-shadow 0.1s ease-in;
	transition: border 0.1s ease-in;
	transition: outline 0.1s ease-in;
	box-shadow: $box-shadow-hover;
}
