
.cfop-list {
	padding: 2vw;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.cfop-card {
	background-color: transparent;
	border: none;
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: .3vw;
	padding-top: 1vw;
	border-bottom: solid 1px $text-secondary;

	& p {
		text-align: left;
	}

	& div {
		display: flex;
		flex-direction: row;
		gap: 1vw;

		& p {
			font-size: 1vw;
			margin: 0;
		}

	}

	&:hover {
		background-color: rgb(37, 42, 48)
	}
}