.itemDetail {
	width: 100%;
	display: flex;
	flex-direction: column;

	& hr {
		width: 100%;
		color: $text-secondary;
		margin: 0.5vw 0;
	}

	& .item-info {
		width: 100%;
		display: grid;
		grid-template-columns: 15vw 2vw 1fr 1fr auto;
		align-items: center;

		& button {
			margin-left: 1vw;
		}

		& .last {
			text-align: right;
		}
	}
}
