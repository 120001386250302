
.default-search {
	display: flex;
	width: 100%;
	padding-bottom: 2vw;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 1vw;
	font-size: 0.9vw;

	& .search-input {
		width: 80%;
		background-color: transparent;
    border: none;
    outline: none;
		color: $text-primary;

		&:focus {
			border-bottom: 1px solid $text-secondary;
		}
	}

	& i {
		color: $secondary;
		font-size: 0.9vw;
	}
}