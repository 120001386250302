//
// Main
//

// Body
body {
	background-color: $page-bg;
}

// Font color from Content background color
.text-page-bg {
	color: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		display: flex;
		flex-wrap: wrap;
		position: relative; // edited
		//padding: 0 get($content-spacing, desktop);
		padding: 0;
	}

	// Wrapper
	.wrapper {
		transition: padding-left get($aside-config, transition-speed) ease,
			margin-right get($aside-config, transition-speed) ease;

		// Aside default enabled and aside fixed modes
		.aside-enabled.aside-fixed & {
			transition: padding-left get($aside-config, transition-speed) ease;
			padding-left: get($aside-config, width);
		}

		.aside-enabled-right.aside-fixed-right & {
			transition: padding-right get($aside-config, transition-speed) ease;
			padding-right: get($aside-config, width);
		}

		// Aside default enabled, aside fixed and aside minimize modes
		.aside-enabled.aside-fixed[data-kt-aside-minimize='on'] & {
			transition: padding-left get($aside-config, transition-speed) ease;
			padding-left: get($aside-config, minimized-width);
		}

		// Fixed header mode
		.header-fixed & {
			padding-top: get($header-config, fixed, height, desktop);
		}

		// Fixed header & fixed toolbar modes
		.header-fixed.toolbar-fixed & {
			padding-top: calc(#{get($header-config, fixed, height, desktop)} + var(--kt-toolbar-height));
		}

		// Fixed Footer mode
		.footer-enabled & {
			transition: padding-bottom get($footer-config, transition-speed) ease;
			padding-bottom: get($footer-config, height);
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		max-width: none;
		//padding: 0 get($content-spacing, tablet-and-mobile);
		padding: 0;
	}

	// Wrapper
	.wrapper {
		// Fixed header mode
		.header-tablet-and-mobile-fixed & {
			padding-top: get($header-config, fixed, height, tablet-and-mobile);
		}

		// Fixed header & fixed toolbar modes
		.header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
			padding-top: calc(
				#{get($header-config, fixed, height, tablet-and-mobile)} + var(--kt-toolbar-height-tablet-and-mobile)
			);
		}
	}
}
