
.issue-detail-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	& .issue-view-container {
		display: flex;
		justify-content: center;
		height: 100%;
		overflow-y: scroll;
	}
}

.defaultNFEContent {
	display: flex;
	flex-direction: column;
	gap: 2vw;
	align-items: center;
	justify-content: center;
	align-self: center;
	justify-self: center;
	height: 100%;
	width: 100%;

	& i {
		font-size: 8vw;
	}
}