
.issue-button {
	width: 100%;
	background-color: $btn-secondary;
	border: none;
	border-left: solid 0.5vw $nfe-default;
	border-radius: 0.05vw;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	gap: 2vw;
	padding: 1vw;
	min-height: 6vw;
	
	&.confirmada {
		border-left: solid 0.5vw $nfe-confirmed;
		&:hover,
		&.selected {
			outline: $nfe-confirmed-outline;
		}
	}

	&.cancelado {
		border-left: solid 0.5vw $nfe-default;
		&:hover,
		&:active,
		&.selected {
			outline: $nfe-default-outline;
		}
	}

	&:hover,
	&:active,
	&.selected {
		outline: $nfe-default-outline;
	}

	p {
		margin-bottom: 0.5vw;

		&.status {
			font-size: 0.7vw;
			font-weight: bold;
			&.confirmada {
				color: $nfe-confirmed;
			}
			&.cancelado {
				color: $nfe-default;
			}
			color: $nfe-default;
		}

		&.issue-client-name {
			font-size: 0.7vw;
			line-height: 1.1vw;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2; /* number of lines to show */
							line-clamp: 2; 
			-webkit-box-orient: vertical;
		}
	}

	& .container {
		display: flex;
		flex-direction: column;

		&.left {
			width: 100%;
			text-align: left;
		}
		&.right {
			width: auto;
			text-align: right;
		}
	}
}

@media (max-width: 1200px)
{
  .issue-button
   {
    flex-direction: column;
		min-height: max-content;
   }

	 .issue-button .container.left {
		text-align: center;
	 }
}
