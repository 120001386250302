
.default-footer-content {
	display: flex;
	width: 100%;
	flex-direction: row;
	text-align: center;
	justify-content: space-between;

	&.center {
		justify-content: center;
		align-items: center;
    gap: 1vw;
	}

	& p {
		margin: 0;
	}

	& .custom-content {
		display: flex;
		align-self: center;
		justify-content: flex-end;
		gap: 1vw;
	}

	& .side-by-side-buttons {
		display: flex;
		flex-direction: row;
		gap: 1vw;
	}
}