
.numericKeyboardContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 1.5vw;

}

.numericKeyboardHeader {
	display: flex;
	flex-direction: row;
	gap: 1vw;
	padding: 1vw;

	& label {
		width: 100%;
		text-align: left;
		align-items: center;
	}
}

.numericKeyboard {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 32%);
	grid-template-rows: repeat(4, 24%);

	margin-top: 2vw;
	margin-bottom: 8vw;

	gap: 1vw;

	& i {
		color: black;
	}
}
