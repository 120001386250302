.aside-modal-acnf {
		background-color: $bg-aside-modal !important;
		max-width: 40vw;
		& .aside-modal-header {
			display: flex;
			width: 100%;
			flex-direction: row;
			padding: 2vw;
			justify-content: space-between;
			text-align: center;
			
			& p {
				margin: 0;
				flex-grow: 1;
				font-size: 1.7rem;
			}

			& .iconButton {
				& i {
					width: 100%;
					color: $primary;
					font-size: 2rem;
				}
			}
		}
	
		& .aside-modal-content {
			width: 100%;
			height: inherit;
			overflow-y: scroll;
			align-items: center;
		}
	}
