
.edit-button {
	border: none;
	width: auto;
	padding: .1vw;
	background-color: transparent;
	margin: 0;
	padding: 0;
	box-shadow: none;
	text-align: left;

	&:hover {
		border-bottom: solid 1px $text-secondary;
		box-shadow: none;
	}

	&.hidden {
		visibility: hidden;
	}

	&:disabled {
		border-bottom: none;
	}
}

.default-card {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-bottom: 0.8vw;
	background-color: $btn-secondary;
	border-bottom: solid 1px $text-secondary;

	& div {
		display: flex;
		flex-direction: row;
		font-size: 1.2vw;
		align-items: center;
		justify-content: space-between;
		padding: .8vw 1vw 0 1vw;

		& p {
			margin: 0;
		}
		& p.primary {
			font-weight: 500;
		}
		& p.secondary {
			font-size: 1vw;
		}
	}
}