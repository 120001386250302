.card-button {
	background-color: $bg-secondary;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	border: none;
	border-radius: 2px;
	border: solid 2px $bg-secondary;

	&:hover {
		outline: $button-outline;
	}

	&.selected {
		outline: $button-outline;
	}

	& p.primary {
		text-transform: capitalize;
	}

	&.large {
		width: 30%;

		font-size: 1.1vw;
		padding: 2vw 1vw 2vw;
		gap: 2vw;

		p.primary {
			font-size: 1.2vw;
		}

		i {
			color: $secondary;
			font-size: 4rem;
		}

	}

	&.small {
		width: 9vw;
		height: 6.5vw;
		padding-top: 0.6vw;
		gap: 0.9vw;

		p.primary {
			font-size: 0.7vw;
		}

		i {
			color: $secondary;
			font-size: 2rem;
		}

	}

	&.with-description {
		width: 23%;
		padding-right: 2vw;
		padding-left: 2vw;

		p.secondary {
			font-size: 0.9vw;
		}

		i {
			color: $secondary;
			font-size: 4rem;
		}
	}

	&.homepage {
		width: 19vw;
	}
}
