
.footer-wrapper {
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	right: 0;

	&.aside-enabled {
		// padding-left: $aside-config;
		padding-left: 25vw;
	}

	&.aside-right-enabled {
		padding-right: 25vw;
	}

	&.footer-disabled {
		visibility: hidden;
	}
}

.footer-component{
	display: flex;
	background-color: $bg-footer;
	padding: 2vw;
	padding-left: 3vw;
	padding-right: 3vw;
	width: 100%;
	align-self: flex-end;
}