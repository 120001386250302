.default-button {
	color: $text-primary;
	border: none;
	border-radius: 0.5vw;
	padding: 0.7vw 2.5vw;
	font-weight: bold;
	font-size: 0.7vw;

	&.large {
		width: 19vw;
		height: 4.5vw;
	}

	&.primary {
		background-color: $btn-primary;
		&:hover {
			background-color: $btn-primary-hover;
		}
	}

	&.secondary {
		background-color: $btn-secondary;
		&:hover {
			background-color: $btn-secondary-hover;
		}
	}

	&.tertiary {
		background-color: $btn-tertiary;
		&:hover {
			background-color: $btn-tertiary-hover;
		}
	}

	&.danger {
		background-color: $btn-danger;
		&:hover {
			background-color: $btn-danger-hover;
		}
	}
	
	&.trashBin {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		width: 2vw;
		height: 2vw;
		border-radius: 50%;
		
		& i {
			color: white;
			align-self :center;
			font-size: 1.3rem;
		}
	}

	&.cancel {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2.5vw;
		height: 2.5vw;
		padding: 0;

		& i {
			color: white;
			align-self :center;
			font-size: 1.5vw;
		}
	}

	&.digit {
		color: black;
		font-size: 1.3vw;
		padding: 0.3vw 2.1vw;
	}

	& i {
		color: white;
		align-self :center;
	}

	&.disabled {
		background-color: $btn-disabled;
		color: $text-secondary;
		&:hover {
			background-color: $btn-disabled;
			box-shadow: $box-shadow;
		}
	}

	&.hidden {
		visibility: hidden;
	}

	& span.svg-icon {
		align-self: center;
	}
}
