
.userIcon {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: flex-end;
	background-color: transparent;
	box-shadow: none;
	gap: 1vw;
	border: none;
	border-radius: 0.5vw;
	padding: 0.7vw 2.5vw;

	font-weight: bold;
	font-size: 1.1vw;

	& i {
		color: $secondary;
		font-size: 2vw;
	}

	&:hover {
		background-color: transparent;
		box-shadow: none;
	}

}