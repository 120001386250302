

.iconButton {
	background-color: transparent;
	border: none;
	box-shadow: none;
	
	&:hover {
		box-shadow: none;
	}

	&.hidden {
		visibility: hidden;
	}
}