
.navigator-header {
	background-color: $bg-footer;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 1vw;
	padding: 1.5vw 2vw 0;

	p.primary {
		font-size: 1.2vw;
	}

	& .title {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	& .navigator-buttons {
		align-self: center;
		justify-self: flex-end;
		display: flex;
		gap: 1vw;
		align-items: center;
	}
}