.form-container{
	display: flex;
	gap: 2vw;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	.modal-input-container {
		width: 25%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.ie{
		flex-grow: 0.5;
	}

	.streetNumber{
		flex-grow: 0.6;
	}

	.form-check {
		justify-content: center;
		min-height: 0.5vw;
	}
}

.form-submit {
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 1vw;
	gap: 2vw;
}

.form-content-label{
	font-size: 1.5rem !important;
	padding-top: 0.2vw;
	padding-bottom: 0.2vw;
	width: 80.5%;
	margin: auto;
}