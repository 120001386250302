.default-modal-footer {
	padding-top: 1.5vw;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: end;
	gap: 1vw;
}

.dafault-modal-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	& i {
		font-size: 1.5vw;
		color: $secondary;
	}
}