.page-content {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	margin: 0;
	padding: 2vw;
	padding-bottom: 3vw;

	gap: 1vw;
	
	& p {
		margin: 0;
	}

	& i {
		font-size: 2vw;
	}

	&.row {
		flex-flow: row wrap;
		justify-content: center;
		align-content: flex-start;
	}

	& .form-check-custom {
		position: absolute;
		top: 0;
		justify-content: flex-end;
		gap: 1vw;
		padding: 2vw;
	}

	&.stretch {
		align-items: stretch;
	}

	& .text-success {
		color: $primary !important
	}

}

div.row {
	display: flex;
	flex-flow: row wrap;
	gap: 1vw;
	align-items: center;
}

div.flex.justify-center {
	display: flex;
	justify-content: center;
}
