
.nfeButtonsList {
	background-color: $bg-primary;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 0;
	padding: 0;
	margin: 0;

	& div.header {
		display: inline-flex;
		align-items: center;
		font-size: 1.1vw;
		background-color: $bg-primary;
		padding: 1vw;
		
		& p {
			margin: 0;
			justify-self: flex-start;
		}
		& i {
			font-size: 1.1vw;
			color: $primary;
		}
	}

	& div.subHeader {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: $bg-secondary;
		padding: 1vw;

		& p {
			margin: 0;
		}
	}

	& div.buttonsList {
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow-y: scroll;
		width: 100%;
		background-color: $bg-primary;
		padding: .3vw;
		gap: .3vw;
	}
}
