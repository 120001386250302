.pageHeader {
	text-align: center;
	padding-top: 60px;
	padding-bottom: 60px;
}

.pageHeader > h1 {
	color: $text-primary;
}

.pageHeader > .page-title {
	font-size: 1.2vw;
}
