// COLOR PALLETE VARIABLES
// Main colors
$primary: #6cae17;
$secondary: #d1a53c;
$tertiary: #c44c4c;

// Background colors
$bg-primary: #2b3643;
$bg-secondary: #364454;
$bg-footer: #252B33;
$bg-aside-modal: #22272d;
$bg-product-card: #1f1f1f;
$bg-input: #ffffffb3;
$bg-input-read-only: #666666;
$bg-overlay: #000000b3;

// Button colors
$btn-primary: #6cae17;
$btn-primary-hover: #6cae17d3;
$btn-secondary: #364454;
$btn-secondary-hover: #364454cf;
$btn-tertiary: #d1a53c;
$btn-tertiary-hover: #d1a43ccb;
$btn-danger: #c44c4c;
$btn-danger-hover: #c44c4cc6;
$btn-disabled: #2b3643;

// Text colors
$text-title: #d1a53c;
$text-primary: #ffff;
$text-secondary: #9d9d9d;

// Shadow colors
$box-shadow: 0 0 1vw #2424247f;
$box-shadow-hover: 0 0 1.5vw #242424c5;

// Border
$button-outline: solid 2px $secondary;

// NFE Status
$nfe-confirmed: #6cae17;
$nfe-default: #8896a7;
$nfe-confirmed-outline: solid 2px $nfe-confirmed;
$nfe-default-outline: solid 2px $nfe-default;


